<template>
    <tr>
        <th class="w-75">
            <span>{{ position }}</span>
            <div v-if="showPositionVariations && value.position_variation" class="is-inline">
                <span v-if="value.position_variation < 0" class="has-text-danger">&#x25BC;</span>
                <span v-if="value.position_variation > 0" class="has-text-success">&#x25B2;</span>
                <span class="is-size-7 has-text-weight-normal" v-if="value.position_variation != 0">
                    <small>{{ (value.position_variation > 0 ? '+' : '') + value.position_variation }}</small>
                </span>
            </div>
        </th>
        <td class="separator" v-bind:class="{'l75': showPositionVariations}">{{ value.complete_normal_name }}</td>
        <template v-for="zone in value.zones">
            <template v-for="peak in zone.peaks">
                <td class="px-1" v-bind:key="'peak_' + peak.id">
                    <Ascencions
                        :times="peak.times"
                        :max-times="zone.max_times"
                    ></Ascencions>
                </td>
            </template>
            <td
                class="px-1 separator has-background-grey-lighter has-border-bottom-white"
                v-bind:key="'zone_' + zone.id"
            >
                <Ascencions
                    :times="zone.times"
                    :max-times="zone.max_times"
                ></Ascencions>
            </td>
        </template>
        <td
            class="px-1 separator"
            v-if="showCombosScore"
            v-bind:class="{ 'has-link': value.combo_score > 0 }"
            v-on:click="emitClickEvent"
        >
            <span>{{ value.combo_score }}</span>
            <Ascencions :times="value.combo_count" :max-times="3"></Ascencions>
        </td>
        <td class="px-1 separator">
            <span
                class="tag"
                v-bind:class="{
                    'is-success': value.is_integral_completed,
                    'is-warning':
                        value.integral_score > 0 &&
                        !value.is_integral_completed,
                }"
            >
            </span>
            <small
                v-if="showIntegralTime && value.is_integral_completed"
                class="d-block is-size-7"
            >
                {{
                    `${Math.floor(value.integral_time / 60)}h ${String(
                        value.integral_time % 60
                    ).padStart(2, "0")}m`
                }}
            </small>
            <small
                v-else-if="showIntegralTime && value.integral_score"
                class="d-block is-size-7"
            >
                {{ value.integral_score }}
            </small>
        </td>
        <td>{{ value.score }}</td>
    </tr>
</template>

<script>
import Ascencions from "../Ascencions.vue";

export default {
    name: "Pilot",
    components: {
        Ascencions,
    },
    props: {
        value: Object,
        position: Number,
        showIntegralTime: Boolean,
        showCombosScore: Boolean,
        showPositionVariations: Boolean,
    },
    methods: {
        emitClickEvent() {
            if (this.value.combo_count) this.$emit("click", this.value.id);
        },
    },
};
</script>

<style scoped>
.has-link {
    cursor: pointer;
}

.tag {
    display: inline;
}

.has-border-bottom-white {
    border-bottom-color: white !important;
}

.w-75 {
    min-width: 75px !important;
}

td,
th {
    vertical-align: middle !important;
}
</style>
