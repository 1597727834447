var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[(_vm.data)?_c('div',{staticClass:"table-container mb-0",staticStyle:{"overflow-y":"auto","max-height":"calc(100vh - 90px)"}},[(_vm.data)?_c('table',{staticClass:"table has-sticky-header has-sticky-column is-fullwidth is-striped"},[_c('thead',[_c('tr',[_c('th',{staticClass:"w-75"},[_vm._v("Pos")]),_c('th',{staticClass:"separator",class:{'l75': _vm.showPositionVariations}},[_vm._v("Nom")]),_vm._l((_vm.data.zones),function(zone){return [_vm._l((zone.peaks),function(peak){return [_c('th',{key:'peak_' + peak.id},[_vm._v(" "+_vm._s(peak.name)+" ")])]}),_c('th',{key:'zone_' + zone.id,staticClass:"separator"},[_vm._v(" "+_vm._s(zone.name)+" ")])]}),(_vm.showCombosScore)?_c('th',{staticClass:"separator"},[_vm._v("Combos")]):_vm._e(),_c('th',{staticClass:"separator",class:{
                            'has-cursor-pointer': _vm.rankingHasSomeIntegral,
                        },on:{"click":function($event){return _vm.setOrdenationCriteria('integral')}}},[_vm._v(" Integral "),(_vm.rankingHasSomeIntegral)?_c('span',{staticClass:"icon",class:{
                                'has-text-grey-light': _vm.orderedBy == 'score',
                            }},[_c('i',{staticClass:"fa-solid fa-caret-down"})]):_vm._e()]),_c('th',{class:{
                            'has-cursor-pointer': _vm.rankingHasSomeIntegral,
                        },on:{"click":function($event){return _vm.setOrdenationCriteria('score')}}},[_vm._v(" Punts "),(_vm.rankingHasSomeIntegral)?_c('span',{staticClass:"icon",class:{
                                'has-text-grey-light':
                                    _vm.orderedBy == 'integral',
                            }},[_c('i',{staticClass:"fa-solid fa-caret-down"})]):_vm._e()])],2)]),_c('tbody',_vm._l((_vm.orderedPilots),function(pilot){return _c('Pilot',{key:pilot.id,attrs:{"value":pilot,"position":pilot.position,"showIntegralTime":true,"showCombosScore":_vm.showCombosScore,"showPositionVariations":_vm.showPositionVariations},on:{"click":_vm.openPilotDetails}})}),1)]):_vm._e(),_c('footer',{staticClass:"container footer"},[(_vm.data.last_update)?_c('small',[_vm._v("Última actualització "+_vm._s(_vm.data.last_update))]):_vm._e(),_c('img',{staticClass:"image mx-auto my-3",attrs:{"src":require("../../assets/puntxat.png"),"width":"100","title":"Tingladu Puntxat"}})])]):_vm._e(),_c('PilotDetails',{attrs:{"pilotId":_vm.pilotDetailsId,"isOpen":_vm.pilotDetailsId != null},on:{"close":_vm.closePilotDetails}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }