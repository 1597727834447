<!--
    Ranking APP used in previous editions
-->
<template>
    <div style="position: relative">
        <div
            class="table-container mb-0"
            style="overflow-y: auto; max-height: calc(100vh - 90px)"
            v-if="data"
        >
            <table
                v-if="data"
                class="table has-sticky-header has-sticky-column is-fullwidth is-striped"
            >
                <thead>
                    <tr>
                        <th class="w-75">Pos</th>
                        <th class="separator" v-bind:class="{'l75': showPositionVariations}">Nom</th>
                        <template v-for="zone in data.zones">
                            <template v-for="peak in zone.peaks">
                                <th v-bind:key="'peak_' + peak.id">
                                    {{ peak.name }}
                                </th>
                            </template>
                            <th
                                class="separator"
                                v-bind:key="'zone_' + zone.id"
                            >
                                {{ zone.name }}
                            </th>
                        </template>
                        <th v-if="showCombosScore" class="separator">Combos</th>
                        <th
                            class="separator"
                            v-on:click="setOrdenationCriteria('integral')"
                            v-bind:class="{
                                'has-cursor-pointer': rankingHasSomeIntegral,
                            }"
                        >
                            Integral
                            <span
                                class="icon"
                                v-if="rankingHasSomeIntegral"
                                v-bind:class="{
                                    'has-text-grey-light': orderedBy == 'score',
                                }"
                            >
                                <i class="fa-solid fa-caret-down"></i>
                            </span>
                        </th>
                        <th
                            v-bind:class="{
                                'has-cursor-pointer': rankingHasSomeIntegral,
                            }"
                            v-on:click="setOrdenationCriteria('score')"
                        >
                            Punts
                            <span
                                class="icon"
                                v-if="rankingHasSomeIntegral"
                                v-bind:class="{
                                    'has-text-grey-light':
                                        orderedBy == 'integral',
                                }"
                            >
                                <i class="fa-solid fa-caret-down"></i>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <Pilot
                        v-for="pilot in orderedPilots"
                        v-on:click="openPilotDetails"
                        v-bind:key="pilot.id"
                        v-bind:value="pilot"
                        v-bind:position="pilot.position"
                        v-bind:showIntegralTime="true"
                        v-bind:showCombosScore="showCombosScore"
                        v-bind:showPositionVariations="showPositionVariations"
                    ></Pilot>
                </tbody>
            </table>
            <footer class="container footer">
                <small v-if="data.last_update">Última actualització {{ data.last_update }}</small>
                <img
                    src="../../assets/puntxat.png"
                    class="image mx-auto my-3"
                    width="100"
                    title="Tingladu Puntxat"
                />
            </footer>
        </div>
        <PilotDetails
            v-bind:pilotId="pilotDetailsId"
            v-bind:isOpen="pilotDetailsId != null"
            v-on:close="closePilotDetails"
        />
    </div>
</template>

<script>
import Pilot from "./Pilot.vue";
import PilotDetails from "../PilotDetails.vue";

export default {
    name: "Ranking",
    props: {
        showCombosScore: {
            type: Boolean,
            default: true,
        },
        showPositionVariations: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        Pilot,
        PilotDetails,
    },
    data() {
        return {
            data: null,
            pilotDetailsId: null,
            orderedBy: "score",
        };
    },
    computed: {
        orderedPilots() {
            if (this.orderedBy == "integral")
                return this.orderedPilotsByIntegral();

            return this.orderedPilotsByScore();
        },
        rankingHasSomeIntegral() {
            for (var i = 0; i < this.data.pilots.length; i++) {
                if (this.data.pilots[i].integral_score > 0) return true;
            }

            return false;
        },
    },
    methods: {
        loadData(data) {
            this.data = data;
        },
        openPilotDetails(id) {
            this.pilotDetailsId = id;
        },
        closePilotDetails() {
            this.pilotDetailsId = null;
        },
        setOrdenationCriteria(criteria) {
            if (this.rankingHasSomeIntegral) this.orderedBy = criteria;
        },
        /**
         * We will calculate position ordinal on runtime and
         * if point are equal, position is nos incremented
         * (to get the effect of a 5th position may be occupied for
         * 3 pilots)
         */
        orderedPilotsByScore() {
            var isDrawCallback = function (pilots, index) {
                return (
                    index > 0 &&
                    pilots[index - 1].score == pilots[index].score &&
                    pilots[index - 1].integral_time ==
                        pilots[index].integral_time
                );
            };

            return this.data.pilots.recalculatePosition(isDrawCallback);
        },
        orderedPilotsByIntegral() {
            var isDrawCallback = function (pilots, index) {
                return (
                    index > 0 &&
                    pilots[index - 1].integral_score ==
                        pilots[index].integral_score &&
                    pilots[index - 1].integral_time ==
                        pilots[index].integral_time
                );
            };

            return [...this.data.pilots]
                .sort((a, b) => {
                    if (
                        a.integral_score < b.integral_score ||
                        (a.integral_score == b.integral_score &&
                            a.integral_time > b.integral_time)
                    )
                        return 1;

                    if (
                        a.integral_score > b.integral_score ||
                        (a.integral_score == b.integral_score &&
                            a.integral_time < b.integral_time)
                    )
                        return -1;

                    return 0;
                })
                .recalculatePosition(isDrawCallback);
        },
    },
    mounted() {
        /*
        this.$http.get("/api/ranking").then((response) => {
            this.data = response.data.data;
            let vm = this;
            setTimeout(function () {
                vm.$root.$emit("ready");
            }, 850);
        });
        */

        Array.prototype.recalculatePosition = function (isDrawCallback) {
            this.forEach((pilot, index) => {
                pilot.position = index + 1;
                if (isDrawCallback(this, index)) {
                    // we will repeat position number if there is a draw
                    pilot.position = this[index - 1].position;
                }
            });

            return this;
        };
    },
};
</script>

<style lang="scss">
th {
    vertical-align: bottom !important;
}
.separator {
    border-right-width: 2px !important;
}
.container-overflow {
    height: calc(100vh - 90px);
    overflow: scroll;
}

.table.has-sticky-column thead th:nth-child(2),
.table.has-sticky-column tbody td:nth-child(2) {
    background-color: white;
    position: sticky;
    left: 75px;
    z-index: 1;
}

.table.has-sticky-column thead th:nth-child(2) {
    z-index: 6;
}

.table.has-sticky-column.is-striped
    tbody
    tr:not(.is-selected):nth-child(2n)
    td {
    background: #fafafa;
}

.w-75 {
    min-width: 75px !important;
}

.container.footer {
    position: sticky;
    left: 0;
}
</style>
