<template>
  <div>
    <HeaderPreviousEditions title="Edició 2023">
      <a
        href="https://hikeandfly.puntxat.cat/admin/storage/hf_bergueda_normativa_2023.pdf"
        class="navbar-item"
        >Normativa</a
      >
    </HeaderPreviousEditions>
    <Ranking v-bind:showPositionVariations="false" ref="ranking"></Ranking>
  </div>
</template>

<script>
import HeaderPreviousEditions from "../components/HeaderPreviousEditions.vue";
import Ranking from "../components/RankingLegacy/Ranking.vue";
import results from "./ranking-2023.json";

export default {
  name: "Edition2023",
  RESULTS2023: results,
  components: {
    HeaderPreviousEditions,
    Ranking,
  },
  mounted() {
    this.$refs.ranking.loadData(this.$options.RESULTS2023.data);
    this.$root.$emit("ready");
  },
};
</script>
